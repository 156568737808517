<template>
  <el-dialog
    title="转保单"
    :visible.sync="dialogVisible"
    custom-class="policy-turn-dialog"
    width="540px"
    :before-close="handleClose"
  >
    <div class="form-item">
      <div class="form-item-label">
        <span style="color: red">*</span> 保单号：
      </div>
      <el-input
        v-model="OriginalFileObj.policyNo"
        style="flex: 1"
        @input="input_text($event, OriginalFileObj, 'policyNo')"
        placeholder="仅允许输入字母、数字和*/-"></el-input>
    </div>
    <div class="form-item">
      <div class="form-item-label"><span style="color: red">*</span> 投保日期：</div>
      <el-date-picker
        v-model="OriginalFileObj.insuranceOfDate"
        type="date"
        placeholder="选择日期时间"
        style="flex: 1"
        value-format="yyyy-MM-dd"
        :end-placeholder="endPlaceholder"
        :picker-options="pickerOptions"
      ></el-date-picker>
    </div>
    <div class="form-item">
      <div class="form-item-label">绑定客户：</div>
      <!-- <el-select
        v-model="OriginalFileObj.bindUserId"
        multiple
        collapse-tags
        filterable
        style="flex: 1"
        placeholder="请选择"
      >
        <el-option
          v-for="item in bindUserOptions"
          :key="item.userId"
          :label="item.userName + ':' + item.phoneNum"
          :value="item.userId"
        ></el-option>
      </el-select> -->
      <el-select
        v-model="OriginalFileObj.bindUserId"
        filterable
        style="flex: 1"
        placeholder="请选择"
      >
        <el-option
          v-for="item in bindUserOptions"
          :key="item.userId"
          :label="item.userName + ':' + item.phoneNum"
          :value="item.userId"
        ></el-option>
      </el-select>
    </div>
    <div class="form-item">
      <div class="form-item-label">出单类型：</div>
      <el-radio-group v-model="OriginalFileObj.seeFee">
        <el-radio :label="true">见费</el-radio>
        <el-radio :label="false">非见费</el-radio>
      </el-radio-group>
    </div>
    <div class="upload-top">
      <el-upload
        drag
        accept=".pdf, .jpg, .png, .jpeg"
        action="https://jsonplaceholder.typicode.com/posts/"
        :on-change="handlePreview"
        multiple
        :limit="1"
        ref="upload-box"
        :auto-upload="false"
      >
        <el-button
          type="primary"
          size="small"
          style="
            color: rgba(0, 128, 255, 1);
            background: #fff;
            border-color: rgba(0, 128, 255, 1);
            margin-bottom: 8px;
          "
          plain
          >上传文件</el-button
        >
      </el-upload>
      <div>
        支持格式：.jpg .jpeg .png .pdf ，单个文件不能超过10MB，最多上传1个文件
      </div>
    </div>
    <div v-if="fileObj.url">
      <div class="policy-turn-dialog-template policy-turn-dialog-b">
        <div class="upload-demo">
          <img
            src="@/assets/images/policy/png.png"
            class="policy-turn-dialog-b-img"
            alt
            v-if="fileObj.fileType == 'png'"
            srcset
          />
          <img
            src="@/assets/images/policy/jpg.png"
            class="policy-turn-dialog-b-img"
            v-else-if="fileObj.fileType == 'jpg'"
            alt
            srcset
          />
          <img
            src="@/assets/images/policy/jpeg.png"
            class="policy-turn-dialog-b-img"
            v-else-if="fileObj.fileType == 'jpeg'"
            alt
            srcset
          />
          <img
            src="@/assets/images/policy/pdf.png"
            class="policy-turn-dialog-b-img"
            v-else-if="fileObj.fileType == 'pdf'"
            alt
            srcset
          />
          <i class="el-icon-circle-close" @click="handleRemove"></i>
          <div style="text-align: center">{{ fileObj.fileName }}</div>
        </div>
      </div>
    </div>
    <div v-else class="add-box">
      <div class="policy-turn-dialog-template policy-turn-dialog-b">
        <el-upload
          class="upload-demo"
          drag
          accept=".pdf, .jpg, .png, .jpeg"
          action="https://jsonplaceholder.typicode.com/posts/"
          :on-change="handlePreview"
          :on-remove="handleRemove"
          multiple
          :limit="1"
          :auto-upload="false"
          :file-list="fileList"
        >
          <img
            src="@/assets/images/policy/upload.png"
            class="policy-turn-dialog-b-img"
            alt
            srcset
          />
          <div class="policy-turn-dialog-b-text">拖拽到此或点击上传表格</div>
        </el-upload>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="handleImport">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  policyArchiveEtp,
  policyArchiveHasExists,
  pageMyCustomer,
} from "@/api/policy";
import { OSSDirectPass } from "@/api/oss";
import dayjs from "dayjs";
export default {
  props: {
    showDialog: Boolean,
    id: {
      type: String,
      default: "",
    },
    bindUserId: {
      type: String,
      default: "",
    },
  },
  model: {
    prop: "showDialog",
    event: "change",
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit("change", false);
        }
      },
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.fileObj = {
            url: "",
          };
          this.fileList = [];
          this.OriginalFileObj = {
            enquiryId: this.id,
            epFileName: "",
            epFileType: "",
            seeFee: true,
            epUrl: "",
            policyNo: "",
            //TODO 改多选
            bindUserId: this.bindUserId,
            insuranceOfDate: '',
            // dayjs(new Date()).format("YYYY-MM-DD")
          };
          this.endPlaceholder = dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss");
          pageMyCustomer({ size: 999 })
            .then((res) => {
              this.bindUserOptions = res.data.records;
            })
            .catch((err) => {
              console.log(err);
            });
          this.dialogVisible = true;
        } else {
          this.dialogVisible = false;
        }
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      bindUserOptions: [],
      fileList: [],
      fileObj: {
        url: "",
      },
      OriginalFileObj: {
        enquiryId: "",
        epFileName: "",
        epFileType: "",
        seeFee: true,
        epUrl: "",
        policyNo: "",
        bindUserId: "",
        insuranceOfDate: "",
      },
      endPlaceholder: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  methods: {
    handleClose(done) {
      this.$confirm("确认关闭？", "", {
        message: "确认关闭？",
        customClass: "confirm-box",
      })
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    handlePreview(e, r) {
      if (e.size / 1024 / 1024 > 10) {
        this.$message.error('上传文件不能大于10MB');
        this.$refs["upload-box"].clearFiles();
        return false;
      }
      this.fileList = [e];
      this.fileObj = {
        fileName: e.name,
        attachmentType: "EP",
        fileType: e.raw.type.split("/")[1],
        url: URL.createObjectURL(e.raw),
      };
      this.$refs["upload-box"].clearFiles();
    },
    handleRemove() {
      this.fileList = [];
      this.fileObj = {
        url: "",
      };
    },
    handleImport() {
      if (!this.OriginalFileObj.policyNo) {
        this.$message.error("请输入保单号");
        return false;
      }
      if (!this.OriginalFileObj.insuranceOfDate) {
        this.$message.error("请选择投保日期！");
        return false;
      }
      policyArchiveHasExists({
        policyNo: this.OriginalFileObj.policyNo,
      })
        .then((res) => {
          if (res.data) {
            this.$message.error("该保单号已存在，请重新输入！");
          } else {
            this.handleSubmit();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSubmit() {
      if (
        dayjs(new Date()).format("YYYY-MM-DD") >
        dayjs(new Date(this.OriginalFileObj.insuranceOfDate)).format(
          "YYYY-MM-DD"
        )
      ) {
        this.$confirm("投保日期小于当前日期, 是否确认转成保单？", "转保单", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            const loading = this.$loading({
              lock: true,
              text: "Loading",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)",
            });
            if (this.fileList.length) {
              OSSDirectPass(this.fileList[0]).then((pdfUrl) => {
                // this.fileObj.url = pdfUrl;
                policyArchiveEtp({
                  ...this.OriginalFileObj,
                  epFileName: this.fileObj.fileName,
                  epFileType: this.fileObj.fileType,
                  epUrl: pdfUrl,
                })
                  .then((res) => {
                    this.$message.success("转保单成功");
                    this.$emit('changeList');
                    this.dialogVisible = false;
                    loading.close();
                  })
                  .catch((err) => {
                    this.$message.error("转保单失败");
                    loading.close();
                  });
              }).catch((err) => {
                loading.close();
              });
            }else {
              const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
              });
              policyArchiveEtp({
                ...this.OriginalFileObj,
              })
                .then((res) => {
                  this.$message.success("转保单成功");
                  this.$emit('changeList');
                  this.dialogVisible = false;
                  loading.close();
                })
                .catch((err) => {
                  this.$message.error("转保单失败");
                  loading.close();
                });
            }
            //  policyArchiveEtp({
            //    ...this.OriginalFileObj
            //  }).then(res => {
            //    this.$message.success('转保单成功');
            //    this.dialogVisible = false;
            //    loading.close();
            //  }).catch(err => {
            //    this.$message.error('转保单失败');
            //    loading.close();
            //  });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
        return false;
      }
      if (!this.fileList.length) {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        policyArchiveEtp({
          ...this.OriginalFileObj,
        })
          .then((res) => {
            this.$message.success("转保单成功");
            this.$emit('changeList');
            this.dialogVisible = false;
            loading.close();
          })
          .catch((err) => {
            this.$message.error("转保单失败");
            loading.close();
          });
        return false;
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      OSSDirectPass(this.fileList[0]).then((pdfUrl) => {
        // this.fileObj.url = pdfUrl;
        policyArchiveEtp({
          ...this.OriginalFileObj,
          epFileName: this.fileObj.fileName,
          epFileType: this.fileObj.fileType,
          epUrl: pdfUrl,
        })
          .then((res) => {
            this.$message.success("转保单成功");
            this.$emit('changeList');
            this.dialogVisible = false;
            loading.close();
          })
          .catch((err) => {
            this.$message.error("转保单失败");
            loading.close();
          });
      });
    },
    // 格式化限制数字和字母
    input_text(e, tiem, key, max, decimal = 2) {
      // 清除"数字"和字母以外的字符
      // tiem[key] = e.replace(/[^\w]/g, "");
      // 清除"数字"和字母 + ( /*- )以外的字符
      tiem[key] = e.replace(/[^0-9A-Za-z\*\/\-]/g, "");
    },
  },
};
</script>

<style lang="scss">
.confirm-box {
  border: none;
  .el-message-box__header {
    background: #4278c9;
    .el-message-box__title {
      color: #fff;
      font-weight: bold;
    }
    .el-message-box__headerbtn {
      top: 4px;
    }
  }
}
.policy-turn-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-upload-dragger {
    width: auto;
    height: auto;
    border: none;
    background-color: transparent;
  }
  .el-upload-list {
    display: none;
  }
  .el-dialog__header {
    font-size: 18px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #4278c9;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
  }
  &-template {
    height: 60px;
    line-height: 60px;
    background: #f3f8ff;
    font-size: 14px;
    color: #333333;
    border-radius: 8px;
    border: 1px dashed #d1e3fe;
    padding: 0 24px;
    margin-bottom: 12px;
    &-span {
      color: #0080ff;
      cursor: pointer;
    }
  }
  &-b {
    height: auto;
    padding: 10px 24px;
    margin-top: 8px;
    &-img {
      display: block;
      width: 70px;
      height: 70px;
      margin: 14px auto 12px;
    }
    &-text {
      text-align: center;
      font-size: 18px;
      margin-bottom: 5px;
      color: #333333;
    }
    .upload-demo {
      position: relative;
      width: 100%;
      .el-upload {
        width: 100%;
      }
      .el-icon-circle-close {
        position: absolute;
        top: 10px;
        left: calc(50% + 20px);
        cursor: pointer;
        transform: translate(-50%, -50%);
      }
    }
  }
  .upload-button {
    display: flex;
    align-items: center;
    padding: 5px 7px;
    height: 32px;
    width: 98px;
    font-size: 14px;
    img {
      height: 100%;
      margin-right: 6px;
    }
  }
  .upload-top {
    display: block;
    > span {
      margin-left: 8px;
      font-size: 12px;
      font-weight: 300;
      color: #333333;
      line-height: 16px;
    }
  }
  .form-item {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    &-label {
      width: 120px;
      color: #333333;
    }
  }
  .blank-policy {
    width: 160px;
    height: 120px;
    display: block;
    margin: auto;
  }
  .types-msg {
    margin-left: 8px;
    font-size: 12px;
    font-weight: 300;
    color: #333333;
    line-height: 16px;
  }
  .add-box {
    .el-upload {
      display: block;
    }
  }
}
</style>
